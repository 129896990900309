import {Avatar, Button, Col, Drawer, Dropdown, Menu, notification, Row, Space} from "antd";
import { UserOutlined, MenuOutlined } from '@ant-design/icons';
import Logo from "../../assets/images/logo.svg";
import {useTranslation} from "react-i18next";
import ChangeLanguage from "./ChangeLanguage";
import {Link, useLocation, useNavigate} from "react-router-dom";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {useEffect, useState} from "react";
import {USER_INFO} from "../../config/config";
import {closeSession} from "../../config/interceptor";
import useFetch from "../../hooks/useFetch";
import {getScopesByRoute} from "../../Pages/Private/comms/helperComms";

const menulist = [
  {
    name: 'menuHome',
    route: '/home'
  },
  {
    name: 'menuProspects',
    route: '/prospects'
  },
  {
    name: 'doubleAffiliate',
    route: '/double_affiliate'
  },
  {
    name: 'possibleAffiliate',
    route: '/possible_affiliate'
  },
  {
    name: 'menuUsers',
    route: '/users'
  },
  {
    name: 'menuDevices',
    route: '/device'
  },
  {
    name: 'menuLanguages',
    route: '/languages'
  },
  {
    name: 'menuCatalogs',
    route: '/catalog'
  },
  {
    name: 'menuBI',
    route: '/power-report'
  }
];
const ROLE_SYSADMIN = [
  {
    name: 'menuUsers',
    route: '/users'
  },
  {
    name: 'menuDevices',
    route: '/device'
  },
  {
    name: 'menuLanguages',
    route: '/languages'
  },
  {
    name: 'menuCatalogs',
    route: '/catalog'
  },
  {
    name: 'menuBI',
    route: '/power-report'
  }
];
const ROLE_USER = [
  {
    name: 'menuHome',
    route: '/home'
  },
  {
    name: 'menuProspects',
    route: '/prospects'
  },
  {
    name: 'menuUsers',
    route: '/users'
  },
  {
    name: 'menuCatalogs',
    route: '/catalog'
  },
  {
    name: 'menuBI',
    route: '/power-report'
  }
];
const ROLE_GUEST = [
  {
    name: 'menuHome',
    route: '/home'
  },
  {
    name: 'menuProspects',
    route: '/prospects'
  },
  {
    name: 'menuBI',
    route: '/power-report'
  }
];

function HeaderLayout() {

  const navigation = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { xxl } = useBreakpoint();
  const { fetchApi } = useFetch();
  const loca = useLocation();
  const [user, setUser] = useState<any>();
  const [menuItems, setMenuItems] = useState<any>([]);

  useEffect(() => {
    setTimeout(() => {
      const userInfo = JSON.parse(localStorage.getItem(USER_INFO) as string);
      setUser(userInfo);
      getCommsMenu();
    }, 500);
  }, []);

  const getCommsMenu = async () => {
    try {
      const dataSend = {
        url: `/catalogs/module/menu`,
      };
      const response = await fetchApi(dataSend);
      if (response?.data?.status === 'success') {
        const menuServer = response.data?.data || [];
        let menuItems = transformMenuData(menuServer);

        // Crear el nuevo item de PowerBi
        const powerBiItem = {
          key: 'powerbi',
          label: (
              <a
                  href="https://app.powerbi.com/reportEmbed?reportId=9b167a71-f657-4d4d-ab42-f8f3acacbb44&autoAuth=true&ctid=96306d57-5eb7-4dc4-a5bd-22a0e5cc0aab"
                  target="_blank"
                  rel="noopener noreferrer"
              >
                PowerBi
              </a>
          ),
        };

        // Agregar el nuevo item al array de menú
        menuItems = [...menuItems, powerBiItem];

        // Actualizar los items en el estado
        setMenuItems(menuItems);
      } else {
        notification.error({ message: 'Error', description: 'Error al obtener el menú' });
      }
    } catch (error) {
      console.error(error);
      notification.error({ message: 'Error', description: 'Error al procesar el menú' });
    }
  };

  const { i18n } = useTranslation();

  const transformMenuData = (data: any) => {
    const urlsAvailable = getScopesByRoute();

    return data.map((item: any, index: number) => {
      // Filtrar los módulos que están en urlsAvailable
      const filteredModules = item.modules.filter((subItem: any) => urlsAvailable.includes(subItem.modUrl));

      // Si no hay módulos y la URL del menú no está disponible, retornamos null
      if (filteredModules.length === 0 && !urlsAvailable.includes(item.modUrl)) {
        return null;
      }

      // Si no hay módulos, pero la URL del menú principal está disponible
      if (filteredModules.length === 0) {
        return {
          key: `menu-${index}`,
          label: (
            <Link to={item.modUrl}>
              {t(item.modName)}
            </Link>
          ),
        };
      } else {
        return {
          key: `menu-${index}`,
          label: (
            <span>
              {t(item.modName)}
            </span>
          ),
          children: filteredModules.map((subItem: any, subIndex: number) => ({
            key: `submenu-${index}-${subIndex}`,
            label: (
              <Link to={subItem.modUrl}>
                {t(subItem.modName)}
              </Link>
            ),
          })),
        };
      }
    }).filter(Boolean); // Eliminar elementos null del resultado final
  };

  const goRoute = (item: any) => {
    if (item.route !== '/home') {
      localStorage.removeItem('filter-home');
    }
    navigation(item.route);
  }

  const logout = () => {
    closeSession();
  }

  const getMenu = (): Array<any> => {

    const menuComms = {
      name: 'menuComms',
      route: '/compromisos/compromiso_2',
    };

    // ROLE_COUNTRY_ADMIN - ROLE_REGION_ADMIN - ROLE_GLOBAL_ADMIN - ROLE_COUNTRY_PROCESS
    if (user?.roles.includes('ROLE_COUNTRY_ADMIN') || user?.roles.includes('ROLE_REGION_ADMIN') || user?.roles.includes('ROLE_GLOBAL_ADMIN') || user?.roles.includes('ROLE_COUNTRY_PROCESS')) {
      const menu = [...menulist];
      if (user?.roles.includes('ROLE_COMMS_USER')) {
        menu.push(menuComms);
      }
      return menu;
    }

    // ROLE_SYSADMIN
    if (user?.roles.includes('ROLE_SYSADMIN')) {
      // Si tiene adicionalmente el rol de ROLE_USER se genera un nuevo menu uniendo el menu de ROLE_SYSADMIN con el de ROLE_USER
      if (user?.roles.includes('ROLE_USER') || user?.roles.includes('ROLE_GUEST')) {
        const additionalMenu = [
          {
            name: 'menuHome',
            route: '/home'
          },
          {
            name: 'menuProspects',
            route: '/prospects'
          }
          ];
        const menu = [...additionalMenu, ...ROLE_SYSADMIN];
        if (user?.roles.includes('ROLE_COMMS_USER')) {
          menu.push(menuComms);
        }
        return menu;
      }
      const menu = [...ROLE_SYSADMIN];
      if (user?.roles.includes('ROLE_COMMS_USER')) {
        menu.push(menuComms);
      }
      return menu;
    }

    // ROLE_USER
    if (user?.roles.includes('ROLE_USER')) {
      const menu = [...ROLE_USER];
      if (user?.roles.includes('ROLE_COMMS_USER')) {
        menu.push(menuComms);
      }
      return menu;
    }
    // ROLE_GUEST
    if (user?.roles.includes('ROLE_GUEST')) {
      const menu = [...ROLE_GUEST];
      if (user?.roles.includes('ROLE_COMMS_USER')) {
        menu.push(menuComms);
      }
      return menu;
    }

    return [];
  }

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <>
              { user?.user?.useName } { user?.user?.useLastName }
            </>
          ),
        },
        {
          key: '2',
          label: (
            <Link to="/profile">
              {t('myProfile')}
            </Link>
            )
        },
        {
          key: '3',
          label: (
            <Button danger type='link' onClick={logout}>
              {t('closeSession')}
            </Button>
          )
        }
      ]}
    />
  );

  return (
    <header className="h-100">
      <Row align="middle" className="h-100">
        <Col flex="auto">
          <Row align="middle" gutter={[5, 10]}>
            <Col>
              <img height={60} src={Logo} alt="SMP" style={{ marginRight: '20px' }}/>
            </Col>
            {
              (xxl) ? (
                <>
                  {
                    getMenu().map((value) => (
                      <Col key={value.name} className="px-1">
                        {
                          (value.name === 'menuComms') ? (
                            <Dropdown overlay={<Menu items={menuItems}></Menu>} placement="bottom">
                              <Button type="text" className={`btn-border-radius ${(loca.pathname === value.route) ? 'active-menu-item' : ''}`}>{t(value.name)}</Button>
                            </Dropdown>
                          ) : (
                            <Button type="text" className={`btn-border-radius ${(loca.pathname === value.route) ? 'active-menu-item' : ''}`} onClick={() => goRoute(value)}>{t(value.name)}</Button>
                          )
                        }
                      </Col>
                    ))
                  }
                </>
              ) : (
                <>
                  <Button type="primary" icon={<MenuOutlined />} onClick={() => setOpen(true)} ghost />
                  <Drawer title="Menú" placement="right" onClose={() => setOpen(false)} open={open}>
                    <Row>
                      {
                        getMenu().map((value) => (
                          <Col span={24} key={value.name} className="px-1">
                            {
                              (value.name === 'menuComms') ? (
                                <Dropdown overlay={<Menu items={menuItems}></Menu>} placement="bottom">
                                  <Button type="text" className={`btn-border-radius ${(loca.pathname === value.route) ? 'active-menu-item' : ''}`}>{t(value.name)}</Button>
                                </Dropdown>
                              ) : (
                                <Button type="text" className={`btn-border-radius ${(loca.pathname === value.route) ? 'active-menu-item' : ''}`} onClick={() => goRoute(value)}>{t(value.name)}</Button>
                              )
                            }
                          </Col>
                        ))
                      }
                    </Row>
                  </Drawer>
                </>
              )
            }
          </Row>
        </Col>
        <Col>
          <Space align="center">
            <ChangeLanguage />
            <Dropdown overlay={menu}>
              <Avatar size={50} icon={<UserOutlined />} />
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </header>
  );
}

export default HeaderLayout;
